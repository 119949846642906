import Application from '@ember/application';
import config from '@rgov/webapp/config/environment';
import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;

  engines = {
    '@rgov-engine/account-registration': {
      dependencies: {
        services: ['properties', 'session', 'webtrends'],
      },
    },
    '@rgov-engine/individual-banking': {
      dependencies: {
        services: ['properties', 'session', 'webtrends'],
      },
    },
    '@rgov-engine/manage-reference-letters': {
      dependencies: {
        services: ['properties', 'session', 'webtrends'],
      },
    },
    '@rgov-engine/foreign-financial-disclosure': {
      dependencies: {
        services: ['properties', 'session', 'webtrends'],
      },
    },
    '@rgov-engine/reviews-panels-meetings': {
      dependencies: {
        services: ['properties', 'session', 'webtrends'],
      },
    },
  }
}

loadInitializers(App, config.modulePrefix);
