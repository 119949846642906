import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import type IamDataService from '@nsf/ember-oauth-iam/services/iam-data-service';
import type AuthSessionService from '@nsf/ember-oauth-iam/services/session';
import type { PropertiesService } from '@nsf/ember-properties-loader';
import { getConfig as semanticDataConfig } from '@nsf/ember-semantic-content-data/configuration';
import type SemanticContentDataService from '@nsf/ember-semantic-content-data/services/semantic-content-data';
import type { RGovConfig } from '@rgov/webapp-config';


export default class ApplicationRoute extends Route {
  @service
  readonly semanticContentData!: SemanticContentDataService;

  @service
  readonly properties!: PropertiesService<RGovConfig>;

  @service
  readonly session!: AuthSessionService;

  @service('IamDataService')
  readonly iam!: IamDataService;

  async beforeModel(transition: Transition & Partial<{targetName: string}>) {
    await this.properties.load();
    await this.session.setup(this.properties.identityProvider);
    const validated = this.session.accessToken ? await this.iam.validateToken(this.session.accessToken) : false;

    semanticDataConfig({
      domain: this.properties.semanticContent?.domain,
    });

    if (this.properties.researchGov?.domain) {
      if (transition.targetName === 'index' || (validated && transition.targetName === 'account.registration.index')) {
        window.location.replace(`${ this.properties.researchGov.domain }/research-web/homepage`);
      }
    }

    return super.beforeModel(transition);
  }

  async model() {
    return {
      layoutContent: await this.semanticContentData.getAll(this.session.accessToken),
    };
  }
}
