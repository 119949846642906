import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class BankingIndexRoute extends Route {
  @service
  declare readonly router: RouterService;

  beforeModel() {
    this.router.replaceWith('banking.individual');
  }
}
