/**
 * Provides a way to check if a variable is an array
 * 
 * const instructions = ['instruction1', 'instruction2', 'instruction3'];
 * 
 * ```handlebars
 * {{#if (is-array this.instructions)}}
 *   <p>This content is array</p>
 * {{/if}}
 *  
 */
export default function isArray(variable) {
    return Array.isArray(variable);
}