import EmberRouter from '@ember/routing/router';
import config from '@rgov/webapp/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL  = config.rootURL;
}

Router.map(function () {
  this.route('account', function () {
    this.mount('@rgov-engine/account-registration', { as: 'registration' });
  });

  this.route('banking', function () {
    this.mount('@rgov-engine/individual-banking', { as: 'individual' });
  });

  this.mount('@rgov-engine/manage-reference-letters', { as: 'manage-reference-letters' });

  this.mount('@rgov-engine/foreign-financial-disclosure', { as: 'foreign-financial-disclosure' });

  this.mount('@rgov-engine/reviews-panels-meetings', { as: 'reviews-panels-meetings' });

  this.route('not-found', { path: '/*path' });
});
