import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import type { PropertiesService } from '@nsf/ember-properties-loader';
import type { RGovConfig } from '@rgov/webapp-config';

export default class PageNotFoundController extends Controller {
  public readonly breadCrumb = 'Page Not Found';

  @service
  public readonly declare properties: PropertiesService<RGovConfig>;

  public get aboutLink() {
    return this.concatURL('about');
  }

  public get helpLink() {
    return this.concatURL('help');
  }

  public get myProfileLink() {
    return this.concatURL('myProfile');
  }

  public get homeLink() {
    return this.concatURL('home');
  }

  protected concatURL(key: 'about' | 'help' | 'myProfile' | 'home') {
    return this.properties.researchGov
      ? `${ this.properties.researchGov.domain }${ this.properties.researchGov[key] }`
      : '#';
  }
}
