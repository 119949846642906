import type Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class AccountIndexRoute extends Route {
  @service
  declare readonly router: RouterService;

  beforeModel(transition: Transition) {
    if (transition.to?.name?.indexOf('registration')) {
      this.router.replaceWith('account.registration');
    }
  }
}
