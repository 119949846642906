import initialize from '@nsf/ember-semantic-content-data/initializers/semantic-content-data';

const initializerData = {
	name: 'semantic-content-data',
	initialize,
}

// If ember-cli-mirage is not installed in the parent app, declaring
// it as a requirement of the DAG would cause an exception.
// eslint-disable-next-line no-undef
if (requirejs.entries['ember-cli-mirage/index']) {
	initializerData.before = 'ember-cli-mirage';
}

export default initializerData;
