import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type AuthSessionService from '@nsf/ember-oauth-iam/services/session';
import type { PropertiesService } from '@nsf/ember-properties-loader';
import type { RGovConfig } from '@rgov/webapp-config';

export default class ApplicationController extends Controller {
  @service
  declare readonly session: AuthSessionService;

  @service
  declare readonly properties: PropertiesService<RGovConfig>;

  get breadCrumb() {
    const href = this.properties.researchGov
      ? `${ this.properties.researchGov.domain }${ this.properties.researchGov.home }`
      : '#';

    return { href, label: 'Home' };
  }

  @action
  handleLoginClick(event: Event) {
    event.preventDefault();
    return this.session.authenticate();
  }

  @action
  handleLogoutClick(event: Event) {
    event.preventDefault();
    return this.session.invalidate();
  }
}
